import JotformEmbed from 'react-jotform-embed';
import React, { Component }from 'react';

export class ContactForm extends Component {
 render (){
   return(
     <JotformEmbed src="https://form.jotform.com/211724082273046" />
   );
 }
}

export default ContactForm;
