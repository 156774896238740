import React from 'react';

function IconSection(props) {
  const text = props.text;

  return (
    <div className={`c-card ${props.cell ? "cell " : ""} ${props.align}`}>
      <img src={props.image} className={`c-icon--main ${props.imageSize}`} alt={props.alttext}/>
      {text &&
        <span className= {`${props.color === 'white' ? "u-text--white " : ""} ${props.align === 'center' ? "u-center " : ""}  u-bold`}>{props.text}</span>
      }
    </div>
  )
}
export default IconSection;
