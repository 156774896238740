import React, {useState} from 'react';
import Logo from './logo';
import {
  Link
} from "react-router-dom";
import HeaderButtons from './headerButtons';

const MobileNav = (props) => {
  const [isActive, setActive] = useState(false);  
  const toggleClass = () => {
      setActive(!isActive);
    };
  return (
    <div>
      <nav className={`menu c-header o-spectrum ${props.color}`} >
        <Link to="/" className="logo-wrapper"><Logo className="logo" /></Link>
        <button className="button primary" type="button"
        onClick={toggleClass}
        >☰ Menu</button>
      </nav>
        <div className={`c-dropdown-menu c-header__dropdown ${isActive ? 'is-active': null}`}>
          <nav className="menu vertical">
            <Link to="/about">About</Link>
            <Link to="/features">Features</Link>
            <Link to="/buildYourCrew">Build Your Crew</Link>
            <Link to="/crewPro">Crew Pro</Link>
            <Link to="/news">News</Link>
            <Link to="/contact">Contact</Link>
            <a className="u-text--primary" href="https://www.paypal.com/donate/?hosted_button_id=YQF5B972XYBX4">Donate to Crew</a>
            <HeaderButtons />
          </nav>
        </div>
      </div>
    )
}

export default MobileNav;
