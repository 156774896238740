import React from 'react';
import IconSection from './iconSection';
import {icons} from './constants';

const platformFeatures = [
  {
    image: `${icons.customize}`,
    description: 'A custom subdomain and fully customizable navigation, colors and texts (Crew is a white label tool).'
  },
  {
    image: `${icons.tracks}`,
    description: 'Program Management via Tracks: courses, onboarding, tasks, resources, forms and deliverables'
  },
  {
    image: `${icons.calendar_color}`,
    description: 'Event Management with attendance, volunteer tracking and Google Maps'
  },
  {
    image: `${icons.megaphone}`,
    description: 'Pop up modals and banners alerts for important announcements'
  },
  {
    image: `${icons.messaging}`,
    description: 'Guided engagement using Groups and Discussion forums and messaging'
  },
  {
    image: `${icons.linegraph}`,
    description: 'Reports and Google Analytics track member engagement and activity'
  },
  {
    image: `${icons.group}`,
    description: 'Membership Management, organizing users by team tags, teams and roles'
  },
  {
    image: `${icons.badge}`,
    description: 'Rewards for engagement and completion with badges and points.'
  },
  {
    image: `${icons.coins}`,
    description: 'Payment processing via Stripe for donations upon user sign up.'
  }
]

function FeaturesGrid() {
  return platformFeatures.map((feature, index) => (
      <IconSection
      cell
      key={index}
      image={feature.image}
      align='center horizontal'
      text={feature.description}
      imageSize='c-icon--w3'
      />
  ));
}

export default FeaturesGrid;
