import React, { Component }from 'react';
import Header from '../header';
import {heros, icons, memberOrgs} from '../constants';
import BigStatement from '../bigStatement';
import MemberOrgs from '../memberOrgs';
import TestimonialCard from '../testimonialCard';
import PricingColumn from '../pricingColumn';
import CallToAction from '../callToAction';
import Footer from '../footer';
import { Grid, Cell } from 'react-foundation';


export class CrewProPage extends Component {
  render() {
    return (
      <div id="crewPro">
        <div className="c-image-hero">
          <Header color="light"/>
          <img src={heros.yarnMovement} alt="Yarn Movement" className="c-image--yarnMovement c-responsive-image"/>
          <div className="c-image-hero__headline">
            <h1 className="u-bold u-text--white">Personalized onboarding, coaching, and feature development.</h1>
            <a href="https://calendly.com/sam_crewforall/crew-1-1-connect-45-minutes" target="_blank"  rel="noopener noreferrer" className="c-image-hero__button text-center primary button">Schedule a Goal Setting Call</a>
          </div>
          <div className="c-image-hero__caption">Crew Member: Shantelle “Lady Bug” Brumfield, Founder of Believe In What You Dream</div>
        </div>
        <div className="grid-container o-section--medium">
          <h6 className=" text-center u-subtitle">Our team has coached over 180 nonprofits worldwide to successfully integrate Crew into their programs.</h6>
          <MemberOrgs></MemberOrgs>
        </div>
        <BigStatement
            direction='horizontal'
            headline='Crew Coaches offer an unparalleled support experience. '
            gradient='membership'
            textColor='u-text--white'
            memberExperience
          />

        <div className="o-section--center">
          <TestimonialCard
            bubble
            primaryColor={memberOrgs.euphrates.primaryColor}
            org="Euphrates Institute"
            type={memberOrgs.euphrates.type}
            quote={memberOrgs.euphrates.quote}
            author= 'Katy Lunardelli'
            role= 'Executive Director'
            image= {memberOrgs.euphrates.staff}
            />
        </div>
        <div className="grid-container o-section--large">
          <h2 className="text-center u-bold">Our Services</h2>
          <p className="u-description--center">Get a coach, cheerleader, and platform designer on your team!</p>
          <Grid className="grid-x grid-margin-x medium-up-3">
              <PricingColumn
                gradientBackground="u-gradient--turquoiseGreen"
                icon={icons.rocket}
                title="Crew Onboarding"
                subtitle="8 weeks of 1:1 support"
                description="A personalized 8-week workshop to help build a Crew experience that matches your real-world process and community. Includes a custom domain and email address for your platform."
                price="$1k"
                buttonText="Schedule a Goal-Setting Call"

              />
              <PricingColumn
                gradientBackground="u-gradient--pinkOrange"
                icon={icons.support}
                title="1-1 Coaching"
                price="$50/month"
                subtitle="Personalized Ongoing Support"
                description="Your own Crew coach to meet your support and troubleshooting needs, new staff training, program redesign, consulting and new cohort launch check-ins."
                buttonText="Learn How Crew Can Support You"

              />
              <PricingColumn
                gradientBackground="u-gradient--pinkPurple"
                icon={icons.castle}
                title="Custom Features and Integrations"
                subtitle="Let's grow together!"
                description="Whether it's a small modification, a new integration or an entirely new feature, we would be honored to work with you to develop a plan and budget to make your vision a reality."
                buttonText="Discuss Your Custom Build Needs"
              />
          </Grid>
          </div>
        <div className="grid-container">
          <Grid className="grid-x medium-up-3">
           <Cell>
              <TestimonialCard
                rating={icons.rating}
                small
                primaryColor={memberOrgs.coFed.primaryColor}
                org="CoFed"
                quote={memberOrgs.coFed.quote}
                author= 'Hnin W. Hnin'
                role= 'CoFed, Executive Director'
                />
           </Cell>
            <Cell>
             <TestimonialCard
              rating={icons.rating}
              small
              primaryColor="rgb(76, 179, 179)"
              org="10 Billion Strong"
              quote='"I am thrilled about the way the Crew Platform can empower our programs and support us in the way we want to run them. We were able to launch our first cohort with very little funding thanks to their support."'
              author='Patrick Arnold'
              role= '10 Billion Strong, Founder'

              />
            </Cell>
           <Cell>
              <TestimonialCard
                rating={icons.rating}
                small
                primaryColor='#4BABD3'
                org="Campus Kitchens"
                quote='"Crew helped us think smarter about community management to engage more students and spend our time and resources wisely. And students really love it!"'
                author= 'Aubree Pierce'
                role= 'Campus Kitchens, Program Specialist'
                />
           </Cell>
          </Grid>
        </div>
        <CallToAction
        headline="Schedule a Goal Setting Call Today!"
        description="Crew Pro starts with a phone call. We can't wait to hear from you!"
        buttonText="Schedule a Goal-Setting Call"
        grantButton
        link="https://calendly.com/sam_crewforall/crew-1-1-connect-45-minutes"
        />
        <Footer />
    </div>
    )
  }
}

export default CrewProPage;
