import React, { Component } from 'react';
import Header from '../header';
import Footer from '../footer';
import {
  Grid,
  Cell
} from 'react-foundation';

export class AcceptableUsePage extends Component {
  render() {
    return (
      <div id="acceptableUse">
        <Header color="dark"/>
        <div className="grid-container sticky">
          <Grid className="grid-margin-x">
            <Cell className="small-12 o-section--large">
              <h2 className="u-bold u-text--mb1">Crew 2030 Acceptable Use Policy</h2>
              <div className="c-banner--success u-text--success">Last Updated: June 2, 2022</div>
              <p className="u-text--small">This Acceptable Use Policy (this “AUP”) describes material and activities that are not allowed in connection with the Service offered by <span className="u-bold">Crew 2030</span> (“<span className="u-bold">Company</span>,” “<span className="u-bold">we</span>,” or “<span className="u-bold">us</span>”). This AUP is not exhaustive, and Company reserves the right to take remedial action in connection with content or uses that are not specifically described below.</p>

              <p className="u-text--small">You are also responsible for violations of this AUP by any of your users (including Authorized Users). Your use of the Service to assist another person in an activity that would violate this AUP if performed by you is a violation of the AUP. Company may modify this AUP at any time by posting a revised version on the Company websites and/or the Service.</p>

              <p className="u-text--small">All capitalized and formally defined terms in this AUP have the same meaning as in the <a href="/termsofservice" className="u-text--primary" target="_blank">Terms of Service</a> or such other written agreement, contract, instrument, or other writing entered into by you and the Company pursuant to which the Service is made available to you.</p>

              <p className="u-text--small"><span className="u-bold">Crew for All is a gift for organizations building a world that works for all.</span> Our mission is to equip program leaders with a powerful community platform to make the world a more loving, just, and sustainable place. This platform’s use is intended for organizations working towards one or more of the Sustainable Development Goals at the local, national or international level.</p>

              <ul className="menu vertical">
                <li><span className="u-bold">Illegal or Abusive Activity or Content.</span> You may not use the Service for any unlawful or abusive purpose. Prohibited activities include:
                  <ul className="c-menu--nested menu vertical">
                    <li><span className="u-bold">Illegal Activities.</span> Any use of the Service to engage in, further, promote, or encourage illegal conduct or activities, including the dissemination of content that has been determined by a court of competent jurisdiction to be unlawful.
                    Fraudulent or Harmful Activities. Engaging in activities that are deceptive or harmful to others, or that would harm Company’s operations or reputation, including offering or disseminating fraudulent goods, services, schemes, or promotions (e.g., make-money-fast schemes, Ponzi and pyramid schemes, phishing, or pharming), deceptively impersonating another person or entity, or engaging in other fraudulent or misleading practices.</li>

                    <li><span className="u-bold">Violation of Intellectual Property Rights.</span> Using the Service in a manner that violates, infringes on or misappropriates the intellectual property or proprietary rights of any third party, including without limitation any rights in or to copyright, patent, trademark, trade secret, privacy or publicity, and publishing content intended to assist others in unlawfully circumventing technical measures intended to protect any such rights.</li>

                    <li><span className="u-bold">Unlawful Pornography.</span> Disseminating child pornography or depictions of nudity or sexual activity obtained or disseminated without the consent of those depicted (e.g., “revenge pornography”).</li>

                    <li><span className="u-bold">Violent, Harassing, or Abusive Content.</span> Content that incites or threatens violence against any person, promotes terrorism, is intended to harass, abuse or invade the privacy of any individual, creates a risk to the physical safety or health of any individual or to public safety or health, or that threatens or encourages harm on the basis of race, ethnicity, national origin, religion, caste, sexual orientation, sex, gender, gender identity, serious disease or disability, or immigration status.</li>

                    <li><span className="u-bold">Controlled Substances.</span> Unlawfully selling or distributing controlled substances, including but not limited to any illegal or prescription drugs.</li>

                    <li><span className="u-bold">Misinformation.</span> The spread of false information or scientifically debunked data.</li>

                    <li><span className="u-bold">Discrimination.</span> Discriminating and/or excluding members based on their sexual orientation, disability, race, religion and/or mental heath.</li>
                  </ul>
                </li>

                <li><span className="u-bold">Security Violations.</span> You may not use the Service to violate the security or integrity of any network, computer or communications system, software application, or network or computing device (each, a “System”). Prohibited activities include:

                  <ul className="c-menu--nested menu vertical">
                    <li><span className="u-bold">Harmful Software.</span> Content, software, or any other technology that may damage, interfere with, surreptitiously intercept, or expropriate any computer system, program, or data, including any viruses, malware, spyware, adware, Trojan horses, worms, or time bombs.</li>

                    <li><span className="u-bold">Unauthorized Access.</span> Accessing or using any System without permission, including attempting to probe, scan, or test the vulnerability of a System or to breach any security or authentication measures used by a System.</li>

                    <li><span className="u-bold">Interception.</span> Monitoring of data or traffic on a System without Company’s permission.</li>
                  </ul>
                </li>

                <li><p className="u-text--small"><span className="u-bold">Vulnerability Testing.</span> You may not attempt to probe, scan, penetrate, or test the vulnerability of a Company system or network, or to breach the Company security or authentication measures, whether by passive or intrusive techniques, or conduct any security or malware research on or using the Service, without Company’s prior written consent.</p></li>

                <li><p className="u-text--small"><span className="u-bold">Our Monitoring and Enforcement.</span> We reserve the right, but have no obligation, to investigate any suspected violation of this AUP or misuse of the Service about which we have actual knowledge. In connection with such investigations, we may report any activity that we suspect violates any law or regulation to appropriate law enforcement officials, regulators, or other appropriate third parties. Such reporting may include disclosing, reviewing and preserving appropriate customer information consistent with applicable law. We also may cooperate with appropriate law enforcement agencies, regulators, or other appropriate third parties to help with the investigation and prosecution of illegal conduct by providing network and systems information related to alleged violations of this AUP.</p></li>

                <li><p className="u-text--small"><span className="u-bold">Consequences of Violation of AUP.</span> If we determine, in our discretion, that you have violated this AUP, we may remove, disable access to, or modify any content or resource and/or to suspend or terminate your use of the Service. We also may intercept or block any content or traffic belonging to you or to users where the Service is being used unlawfully or not in accordance with this AUP. Our right to suspend or terminate your use of the Service applies even if a violation is committed unintentionally or without your authorization. Such right is not an obligation on us to act at any given time or with respect to any given user, and we may exercise such right at any time within our sole discretion.</p></li>

                <li><p className="u-text--small"><span className="u-bold">Reporting of Violations of this AUP.</span> If you become aware of any suspected violation of this AUP, please notify us by providing a full explanation of the bases for the violation. We may request your assistance to help stop or remedy the violation. To report any violation of this AUP or make related inquiries, please contact us at  <a href="mailto:support@Crew2030.org" className="u-text--primary c-menu-link--inline">support@crew2030.org</a>.</p></li>
              </ul>
            </Cell>
          </Grid>
        </div>
        <Footer />
      </div>
    )
  }
}
export default AcceptableUsePage
