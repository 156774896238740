import { Switch, Route } from 'react-router-dom';
import React from 'react';

// Components
import HomePage from './pages/home';
import AboutPage from './pages/about';
import FeaturesPage from './pages/features';
import CrewProPage from './pages/crewPro';
import BuildYourCrewPage from './pages/buildYourCrew';
import NewsPage from './pages/news';
import ContactPage from './pages/contact';
import TermsOfServicePage from './pages/termsOfService';
import PrivacyPolicyPage from './pages/privacyPolicy';
import GDPRPage from './pages/gdpr';
import AcceptableUsePage from './pages/acceptableUse';
import RegistrationSuccessPage from './pages/registrationSuccess';
import DemoScheduledPage from './pages/demoScheduled';

const routes = () => (
  <Switch>
    <Route exact path="/" component={HomePage}/>
    <Route path="/about" component={AboutPage}/>
    <Route path="/features" component={FeaturesPage}/>
    <Route path="/crewPro" component={CrewProPage}/>
    <Route path="/buildYourCrew" component={BuildYourCrewPage}/>
    <Route path="/news" component={NewsPage}/>
    <Route path="/contact" component={ContactPage}/>
    <Route path="/termsofservice" component={TermsOfServicePage}/>
    <Route path="/privacypolicy" component={PrivacyPolicyPage}/>
    <Route path="/gdpr" component={GDPRPage}/>
    <Route path="/acceptableuse" component={AcceptableUsePage}/>
    <Route path="/registration-success" component={RegistrationSuccessPage}/>
    <Route path="/calendly-confirmed" component={DemoScheduledPage}/>
  </Switch>
);

export default routes;
