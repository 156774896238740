import React from 'react';
import IconSection from './iconSection';
import {integrationLogos} from './constants';

const integrations = [
  {
    image: `${integrationLogos.googleMaps}`,
    description: 'Locate events, users and teams with Google Maps'
  },
  {
    image: `${integrationLogos.stripe}`,
    description: 'Accept and process donations and payments with Stripe'
  },
  {
    image: `${integrationLogos.googleAnalytics}`,
    description: 'Record and report platform data with the help of Google Analytics'
  },
  {
    image: `${integrationLogos.salesforce}`,
    description: 'Share your member data with Crew\'s Salesforce integration'
  },
]

function Integrations() {
  return integrations.map((integration, index) => (
      <IconSection
      key={index}
      image={integration.image}
      align='center'
      text={integration.description}
      imageSize='c-image--100'
      />
  ));
}

export default Integrations;
