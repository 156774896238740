import React from 'react';
import IconSection from './iconSection';
import {icons} from './constants';

const impacts = [
  {
    image: `${icons.plant}`,
    description: 'To help our members become more scalable, effective and sustainable.'
  },
  {
    image: `${icons.justice}`,
    description: 'To foster a community of programs evolving together to transform society in systemic ways.'
  },
  {
    image: `${icons.wonderWoman}`,
    description: 'To help our members’ volunteers, students and community members be equipped to lead and implement social change.'
  }
]

function CrewImpact() {
  return impacts.map((impact, index) => (
      <IconSection
      key={index}
      cell='cell'
      image={impact.image}
      align='center'
      text={impact.description}
      imageSize='c-image--100'
      />
  ));
}

export default CrewImpact;
