import React from 'react';
import {
  Link
} from "react-router-dom";
import {icons} from './constants';
import Icon from './icon';
import Logo from './logo';

function CopyrightYear(){
  return (
    new Date().getFullYear()
  )
}

function Footer(props){
  return (
    <footer className="c-footer__wrapper" style={props.absoluteBottom ? {position:"fixed", width: "100%", bottom: 0} : {}}>
      <div className="grid-container">
        <div className="c-footer-row">
          <ul className="menu c-footer__menu">
            <Link to="/" className="logo-wrapper"><Logo className="logo" /></Link>
            <a className="c-footer__icon-link" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/crewplatform/"><Icon icon={icons.instagram} className="c-icon--white c-icon--w1_5" viewportWidth="36" viewportHeight="36" /></a>
            <a className="c-footer__icon-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/crewplatform/"><Icon icon={icons.linkedin} className="c-icon--white c-icon--w1_5" viewportWidth="36" viewportHeight="36" /></a>
            <a className="c-footer__icon-link" target="_blank" rel="noopener noreferrer" href="https://twitter.com/TheCrewPlatform"><Icon icon={icons.twitter} className="c-icon--white c-icon--w1_5" viewportWidth="36" viewportHeight="36"/></a>
            <a className="c-footer__icon-link" target="_blank" rel="noopener noreferrer" href="mailto:info@crew2030.org"><Icon icon={icons.envelope} className="c-icon--white c-icon--w1_5" viewportWidth="22" viewportHeight="14"/></a>
            <li className="c-footer__item u-text--o50">Copyright &copy; <CopyrightYear /></li>
          </ul>
          <ul className="menu c-footer__menu">
            <a className="c-footer__link" target="_blank" rel="noopener noreferrer" href="/contact">Contact Us</a>
            <a className="c-footer__link" target="_blank" rel="noopener noreferrer" href="https://www.crewplatform.org">Crew Support</a>
            <Link className="c-footer__link" to="/gdpr">GDPR</Link>
            <Link className="c-footer__link" to="/privacypolicy">Privacy Policy</Link>
            <Link className="c-footer__link" to="/acceptableuse">Acceptable Use</Link>
            <Link className="c-footer__link" to="/termsofservice">Terms of Service</Link>
          </ul>
        </div>

      </div>
    </footer>
  );
}

export default Footer;
