import React, { Component } from 'react';
import {Grid} from 'react-foundation';
import Header from '../header';
import Footer from '../footer';
import BigStatement from '../bigStatement';
import Integrations from '../integrations';
import FeaturesList from '../featuresList';
import MemberOutcomes from '../memberOutcomes.js'
import {heros, icons} from '../constants.js';

class FeaturesPage extends Component {
  render() {
    return (
      <div id="features">
        <Header color="dark"/>
        <div className="sticky grid-container">
          <div className="o-section--large o-row-section medium">
            <div>
              <h1 className="u-title">Manage Your Programs, Grow Your Community.</h1>
              <p className="u-description">The Crew Platform is consistently informed and inspired by all of our member organizations.</p>
            </div>
            <div>
              <img src={heros.featuresHero} alt="Crew Features"/>
            </div>
          </div>
        </div>
        <div className="o-section--large u-gradient--features">
          <div className="grid-container c-wrapper--900">
            <h2 className="u-center u-title">Nurture, cultivate and mobilize your community.</h2>
            <p className="u-text--light u-description u-center"><a target="_blank" rel="noopener noreferrer" href="https://www.openimpact.io" className="u-text--primary"> Open Impact</a> identified the following consistent outcomes from communities using Crew Platform:</p>
            <Grid className="grid-margin-x small-up-2 medium-up-3 large-up-4">
              <MemberOutcomes />
            </Grid>
          </div>
        </div>
        <FeaturesList />
        <BigStatement
          direction='horizontal'
          image={icons.gradientSquiggle}
          textColor='u-text--primary'
          headline='Insightful Analytics'
          gradient='gray'
          paragraph='Gather insightful, real-time information with Crew’s reporting tools. See what’s working and what’s not, track user progress, and use the data to build reports, write grants and more. '
        />
        <div className="grid-container o-section--large">
          <h6 className="text-center u-subtitle">Integrations</h6>
          <div className="o-row-section medium">
            <Integrations />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default FeaturesPage;
