import React from 'react';
import IconSection from './iconSection';
import {icons} from './constants';

//Images


const memberExperienceFeatures = [
  {
    image: `${icons.oneOnOne_white50}`,
    description: 'One on One Onboarding Workshop'
  },
  {
    image: `${icons.userGroup_white50}`,
    description: 'On demand new staff training'
  },
  {
    image: `${icons.videoCall_white50}`,
    description: 'New cohort launch review'
  },
  {
    image: `${icons.classroom_white50}`,
    description: 'Program redesign coaching.'
  },
  {
    image: `${icons.internet_white50}`,
    description: 'Platform adoption strategy.'
  },
  {
    image: `${icons.chat_white50}`,
    description: 'Ongoing personalized platform support.'
  }
]

function MemberExperienceFeatures() {
  return memberExperienceFeatures.map((feature, index) => (
      <IconSection
      key={index}
      cell
      color='white'
      image={feature.image}
      text={feature.description}
      imageSize='c-icon--w2_5'
      />
  ));
}

export default MemberExperienceFeatures;
