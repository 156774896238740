import React from 'react';


function PricingColumn(props) {
 //  const benefits = props.benefits;
 //  const benefitsList = benefits.map(benefit =>
 //   <li>{benefit}</li>
 // );

  return (
    <div className={`c-card c-card--column cell`}>
      <div className={`c-card__header text-center ${props.gradientBackground}`}>
        <img src={props.icon} className={`c-icon--w2_5 u-text--mb1`} alt=""/>
        <div className=" u-text--white">
          <h3 className="u-bold">{props.title}</h3>
          <h3 className="u-bold">{props.price}</h3>
        </div>
      </div>
      <div className="c-card__body">
        <p className="u-bold text-center">{props.subtitle}</p>
        <span className="u-text--small">{props.description}</span>
      </div>
      <div className="c-card__footer">
        <a href="https://calendly.com/sam_crewforall/crew-1-1-connect-45-minutes" className="button default expanded">{props.buttonText}</a>
      </div>
    </div>
  )
  }
export default PricingColumn;
