import React, { Component } from 'react';
import Header from '../header';
import Footer from '../footer';
import {
  Grid,
  Cell
} from 'react-foundation';

export class GDPRPage extends Component {
  render() {
    return (
      <div id="gDPR">
        <Header color="dark"/>
        <div className="grid-container sticky">
          <Grid className="grid-margin-x">
            <Cell className="small-12 o-section--large">
            <h2 className="u-bold">GDPR</h2>
            <p className="u-text--small">Crew 2030, which processes the personal data of individuals in the European Union. European Economic Area and/or UK, in either the role of 'data controller' or 'data processor', has appointed DataRep as its Data Protection Representative for the purposes of GDPR*.</p>
            <p className="u-text--small">If Crew 2030 has processed or is processing your personal data, you may be entitled to exercise your rights under GDPR in respect of that personal data. For more details on the rights you have in respect of your personal data. please refer to the <a className="u-text--primary" href="https://ec.europa.eu/info/law/law-topic/dataprotection/data-protection-eu_en">European Commission</a> or the national Data Protection Authority in your country.</p>
            <p className="u-text--small">Crew 2030 takes the protection of personal data seriously. and has appointed DataRep as their Data Protection Representative in the European Union so that you can contact them directly in your home country. DataRep has locations in each of the 27 EU countries, the UK, and Norway & Iceland in the European Economic Area (EEA), so that Crew 2030's customers can always raise the questions they want with them.</p>
            <p className="u-text--small">If you want to raise a question to Crew 2030, or otherwise exercise your rights in respect of your personal data, you may do so by:
              <ul className="menu vertical c-menu--nested c-menu--bullets">
                <li>sending an email to DataRep at <a className="c-menu-link--inline u-text--primary" href="mailto:datareguest@datarep.com">datareguest@datarep.com</a> quoting <span className="u-bold">'Crew 2030'</span> in the subject line,</li>
                <li>contacting us via our <a className="c-menu-link--inline u-text--primary" href="www.datarep.com/data-request">online webform</a>, or</li>
                <li>mailing your inquiry to Data Rep at the most convenient of the addresses in the subsequent pages.</li>
              </ul>
            </p>
            <p className="u-text--small"><span className="u-bold">PLEASE NOTE: when mailing inquiries, it is ESSENTIAL that you mark your letters for 'DataRep'</span> and not 'Crew 2030', or your inquiry may not reach us. Please refer clearly to Crew 2030 in your correspondence. On receiving your correspondence, Crew 2030 is likely to request evidence of your identity, to ensure your personal data and information connected with it is not provided to anyone other than you.</p>
            <p className="u-text--small">If you have any concerns over how Data Rep will handle the personal data we will require to undertake our services, please refer to our <a className="u-text--primary" href="www.datarep.com/privacy-policy">privacy notice</a>.</p>
            <p><span className="u-bold">List of DataRep postal mail addresses:</span></p>
            <p className="u-text--small">If you have any questions, comments, or concerns about our processing activities, please email us at <a href="mailto:info@crew2030.org" className="u-text--primary c-menu-link--inline">info@crew2030.org</a> or write to us at <span className="u-bold">PO Box 341892, Austin, Texas 78734</span>.</p>
            <p className="u-text--small">If you are located in the EEA or the UK,  you may contact our EU and UK Representative for questions or to exercise your data protection rights by: (i) sending an email to datarequest@datarep.com quoting in the subject line; (ii) contacting them via their online webform at www.datarep.com/data-request; or (iii) mailing your inquiry to the most convenient address on the list available here. <span className="u-bold">When mailing inquiries, please address your letter to ‘DataRep’ and not ‘Crew 2030’.</span></p>
            <table>
              <thead>
                <tr>
                  <th width="300">Country</th>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Austria</td>
                  <td>DataRep, City Tower, Bruckenkopfgasse 1/6. Stock, Graz, 8020, Austria</td>
                </tr>
                <tr>
                  <td>Belgium</td>
                  <td>DataRep, Place de L'Universite 16, Louvain-La-Neuve, Waals Brabant, 1348, Belgium</td>
                </tr>
                <tr>
                  <td>Bulgaria</td>
                  <td>DataRep, 132 Mimi Balkanska Str., Sofia, 1540, Bulgaria</td>
                </tr>
                <tr>
                  <td>Croatia</td>
                  <td>DataRep, Ground & 9th Floor, Hoto Tower, Sayska cesta 32, Zagreb, 10000, Croatia</td>
                </tr>
                <tr>
                  <td>Cyprus</td>
                  <td>DataRep, Victory House, 205 Archbishop Makarios Avenue, Limassol, 3030, Cyprus</td>
                </tr>
                <tr>
                  <td>Czech Republic</td>
                  <td>DataRep, IQ Ostrava Ground floor, 28. rijna 3346/91, Ostrava-mesto, Morayska, Ostrava, Czech Republic</td>
                </tr>
                <tr>
                  <td>Denmark</td>
                  <td>DataRep, Lautruphoj 1-3, Ballerup, 2750, Denmark</td>
                </tr>
                <tr>
                  <td>Estonia</td>
                  <td>DataRep, 2nd Floor, Tornimae 5, Tallinn, 10145, Estonia</td>
                </tr>
                <tr>
                  <td>Finland</td>
                  <td>DataRep, Luna House, 5.krs, Mannerheimintie 12 B, Helsinki, 00100, Finland</td>
                </tr>
                <tr>
                  <td>France</td>
                  <td>DataRep, 72 rue de Lessard, Rouen, 76100, France</td>
                </tr>
                <tr>
                  <td>Germany</td>
                  <td>DataRep, 3rd and 4th floor, Altmarkt 10 B/D, Dresden, 01067, Germany</td>
                </tr>
                <tr>
                  <td>Greece</td>
                  <td>DataRep, 24 Lagoumitzi str, Athens, 17671, Greece</td>
                </tr>
                <tr>
                  <td>Hungary</td>
                  <td>DataRep, President Centre, Kalman Imre utca 1, Budapest, 1054, Hungary</td>
                </tr>
                <tr>
                  <td>Iceland</td>
                  <td>DataRep, Kalkofnsvegur 2, 101 Reykjavik, Iceland</td>
                </tr>
                <tr>
                  <td>Ireland</td>
                  <td>DataRep, The Cube, Monahan Road, Cork, T12 H1XY, Republic of Ireland</td>
                </tr>
                <tr>
                  <td>Italy</td>
                  <td>DataRep, Viale Giorgio Ribotta 11, Piano 1, Rome, Lazio, 00144, Italy</td>
                </tr>
                <tr>
                  <td>Latvia</td>
                  <td>DataRep, 4th & 5th floors, 14 Terbatas Street, Riga, LV-1011, Latvia</td>
                </tr>
                <tr>
                  <td>Liechtenstein</td>
                  <td>DataRep, City Tower, Bruckenkopfgasse 1/6. Stock, Graz, 8020, Austria</td>
                </tr>
                <tr>
                  <td>Lithuania</td>
                  <td>DataRep, 44A Gedimino Avenue, 01110 Vilnius, Lithuania</td>
                </tr>
                <tr>
                  <td>Luxembourg</td>
                  <td>DataRep, BPM 335368, Banzelt 4 A, 6921, Roodt-sur-Syre, Luxembourg</td>
                </tr>
                <tr>
                  <td>Malta</td>
                  <td>DataRep, Tower Business Centre, 2nd floor, Tower Street, Swatar, BKR4013, Malta</td>
                </tr>
                <tr>
                  <td>Netherlands</td>
                  <td>DataRep, Cuserstraat 93, Floor 2 and 3, Amsterdam, 1081 CN, Netherlands</td>
                </tr>
                <tr>
                  <td>Norway</td>
                  <td>DataRep, C.J. Hambros Plass 2c, Oslo, 0164, Norway</td>
                </tr>
                <tr>
                  <td>Poland</td>
                  <td>DataRep, Budynek Fronton ul Kamienna 21, Krakow, 31-403, Poland</td>
                </tr>
                <tr>
                  <td>Portugal</td>
                  <td>DataRep, Torre de Monsanto, Rua Afonso Praca 30, 7th floor, Alges, Lisbon, 1495-061, Portugal</td>
                </tr>
                <tr>
                  <td>Romania</td>
                  <td>DataRep, 15 Piaa Charles de Gaulle, nr. 1-T, Bucure§ti, Sectorul 1, 011857, Romania</td>
                </tr>
                <tr>
                  <td>Slovakia</td>
                  <td>DataRep, Apollo Business Centre II, Block E / 9th floor, 4D Prievozska, Bratislava, 821 09, Slovakia</td>
                </tr>
                <tr>
                  <td>Slovakia</td>
                  <td>DataRep, Apollo Business Centre II, Block E / 9th floor, 4D Prievozska, Bratislava, 821 09, Slovakia</td>
                </tr>
                <tr>
                  <td>Slovenia</td>
                  <td>DataRep, Trg. Republike 3, Floor 3, Ljubljana, 1000, Slovenia</td>
                </tr>
                <tr>
                  <td>Spain</td>
                  <td>DataRep, Calle de Manzanares 4, Madrid, 28005, Spain</td>
                </tr>
                <tr>
                  <td>Sweden</td>
                  <td>DataRep, S:t Johannesgatan 2, 4th floor, Malmo, SE - 211 46, Sweden</td>
                </tr>
                <tr>
                  <td>United Kingdom</td>
                  <td>DataRep, 107-111 Fleet Street, London, EC4A 2AB, United Kingdom</td>
                </tr>
              </tbody>
            </table>
            </Cell>
          </Grid>
        </div>
        <Footer />
      </div>
    )
  }
}

export default GDPRPage;
