import React, { Component} from 'react';
import Footer from '../footer'
import Header from '../header';
import InsertModal from '../insertModal';
import Banner from '../banner';
import BigStatement from '../bigStatement';
import CallToAction from '../callToAction';
import MemberOrgTypes from '../memberOrgTypes';
import FeaturesGrid from '../featuresGrid';
import Crew2030 from '../crew2030';
import {icons} from '../constants.js';
import {
  Grid,
  Link,
  Colors,
  Sizes
} from 'react-foundation';

import crewForAll from '../../img/logos/crewForAll.svg';


class BuildYourCrewPage extends Component {
  scrollDown() {
    window.scrollBy({
    top: 700, // negative value acceptable
    behavior: 'smooth'
  });
  }

  render() {
    return (
      <div id="crewForAll">
      <Header color="dark"/>
      <div className="sticky">
        <div className="c-image--crewForAll o-section--image o-section--large o-section--center space-between">
          <div>
            <img src={crewForAll} alt="Crew For All logo" className="c-image--200 c-icon--main c-icon--center" />
            <div className="u-background--white">
            <h2 className="o-section__headline u-bold u-title u-center">The Crew Platform is our gift to volunteer movements, accelerators and education programs working to create a world where all life can thrive.</h2>
              <p className="u-description--center u-text--so50">Crew For All is made possible by generous contributions from the Enlight Foundation and Wendy’s Wish Giving Fund, and dozens of nonprofits who contribute each year to build new features.</p>
            </div>
          </div>
          <div>
            <button className="u-text--primary u-text--large c-button--center" onClick={this.scrollDown}>Read on to See if Crew is Right for You &darr;</button>
          </div>
        </div>
      </div>
      <BigStatement
        textColor='u-text--white'
        subtitle='What is Included?'
        headline='Our gift includes a new instance of the Crew Platform, ready to be customized to your community and program! Plus, access to our Tutorials and Support Platform.'
        gradient='crewForAll'
      />
      
      <div className="grid-container">
        <div className="o-section--medium o-section--center">
          <h6 className="u-subtitle">More Details</h6>
          <h3 className="u-bold">What can you expect with your customized platform ?</h3>
          <p>At Crew, all platforms features are informed and co-created by our organizations, and available to everyone.</p>
          <Grid className="grid-margin-x medium-up-3 u-text--mb2">
            <FeaturesGrid />
          </Grid>
          <Link className="u-text--mt1 u-text--mb2" href="/features" size={Sizes.Large} color={Colors.PRIMARY}>Read More About Platform Features</Link>
          <div className="c-banner--default">
            <h4 className="u-bold u-center">Here to Support You and Your Mission</h4>
            <p>We offer free tutorials and platform documentation on the <a className="u-text--primary" href="https://www.crewplatform.org/" target="_blank" rel="noopener noreferrer">Crew Support and Tutorials Platform</a>. For more personalized onboarding, support or custom feature development check out our <a href="/crewPro" className="u-text--primary">Coaching and Customization Services</a>!</p>
          </div>
          </div>
          <div className="o-section--medium o-section--center">
          <h3 className="u-bold">Is Crew a good fit for your organization? </h3>
          <p className="u-center">We know how busy you and your members are helping make the world a better place! We honor your time and want to make sure we are the right tool for you. In the last five years we have learned that Crew is best suited to support the following types of programs in guiding the engagement experiences of their members:</p>
          <Grid className="grid-margin-x medium-up-3 large-up-5">
            <MemberOrgTypes />
          </Grid>
          <p className="u-text--small u-center u-text--light">*For legal purposes, CFA requires your members are ages 13+, or ages 16+ in the European Union.</p>
          <Grid className="grid-margin-x medium-up-2">
            <div className="cell c-card success c-card--shadow">
            <img src={icons.checkmark} alt="checkmark" className="c-icon--w2 c-icon--main c-icon--center" />
            <h4 className="u-bold u-center">Crew is a good fit for you if:</h4>
            <ul>
              <li>You convene members to grow individually and work toward making their communities a better place.</li>
              <li>You need a platform to manage your organization’s curriculum, training, onboarding processes or tasks.</li>
              <li>You need a platform to manage your events, office hours or coaching meetings.</li>
              <li>You need a platform for internal communication via messaging, groups or discussion forums.</li>
              <li>Your organization uses badges to reward member engagement and curriculum completion.</li>
              <li>You’re hoping to track participation and engagement and view reports and metrics.</li>
              <li>You and your program adheres to Crew's Acceptable Use Policy.</li>
            </ul>
            <span className="u-bold">If this sounds like you, we invite you to start <a className="u-text--primary"  href="/"  target="_blank" rel="noopener noreferrer">building your Crew!</a></span>
          </div>
          <div className="cell c-card alert c-card--shadow">
            <img src={icons.prohibit} alt="prohibit icon"className="c-icon--w2 c-icon--center c-icon--main" />
            <h4 className="u-center u-bold">Crew is not a good fit for you if:</h4>
            <ul>
              <li>You are looking for a private social media platform.</li>
              <li>You expect members to interact independently and spontaneously, without guidance or content to engage with. </li>
              <li>You don’t have a guided program to offer such as a curriculum, a volunteer program, an onboarding session, a fellowship, or an accelerator.</li>
              <li>You are looking for a CRM solution.</li>
              <li>You are looking for monetization solutions other than a one-time donation.</li>
            </ul>
            <span className="u-bold">If this sounds like you, the Crew Platform is likely not a good fit for your community. If you're looking to create a private social media platform, check out <a href="https://www.hylo.com"  className="u-text--primary" target="_blank" rel="noopener noreferrer">Hylo</a> or <a href="https://www.mightynetworks.com"  target="_blank" rel="noopener noreferrer"className="u-text--primary">Mighty Networks</a>. If you're looking to sell your content, check out <a href="https://www.kajabi.com"  target="_blank" rel="noopener noreferrer"className="u-text--primary">Kajabi</a>.</span>
          </div>
          </Grid>
        </div>
      </div>
      <CallToAction
        headline="Ready to Build Your Crew?"
        description="Great! Start building your platform today! The process will take between 5-10 minutes and begins with a short grant application, followed by a guided process to create your platform."
        buttonText="Create a Platform"
        link="https://www.crewplatform.org/platform/welcome"  
      />
      <Footer />

    </div>
    )
  }
}

export default BuildYourCrewPage;
