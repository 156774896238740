import React from 'react';
import IconSection from './iconSection';
import {icons} from './constants';

const memberOutcomes = [
  {
    image: `${icons.lightbulb}`,
    description: 'Increased operational efficiency'
  },
  {
    image: `${icons.edit}`,
    description: 'Documented program'
  },
  {
    image: `${icons.handshake}`,
    description: 'Strengthened sense of community'
  },
  {
    image: `${icons.puzzleHeart}`,
    description: 'Quality relationship building support'
  },
  {
    image: `${icons.fundraising}`,
    description: 'Collected stories, metrics for fundraising'
  },
  {
    image: `${icons.participation}`,
    description: 'Enhanced member participation'
  },
  {
    image: `${icons.scalability}`,
    description: 'Expanded reach and scalability'
  },
  {
    image: `${icons.leader}`,
    description: 'Identified natural leaders'
  }
]

function MemberOutcomes() {
  return memberOutcomes.map((outcome, index) => (
      <IconSection
      cell
      key={index}
      image={outcome.image}
      align='center'
      text={outcome.description}
      imageSize='c-icon--w2_5'
      />
  ));
}

export default MemberOutcomes;
