import React from 'react';
import IconSection from './iconSection.js';
import {icons} from './constants';

const org_types =  [
  {
    id:`volunteerMovement`,
    icon: `${icons.hands}`,
    text: 'Volunteer Movements, Volunteer Organizations',
  },
  {
    id: `fellowship`,
    icon: `${icons.fellowship}`,
    text: 'Fellowship Programs',
  },
  {
    id: 'educationMovement',
    icon: `${icons.chalkboard}`,
    text: 'Education Programs, Train-the-trainer Models',
  },
  {
    id: 'competition',
    icon: `${icons.badge}`,
    text: 'Accelerators and Competitions',
  },
  {
    id: 'associationsCoalitions',
    icon: `${icons.network}`,
    text: `Associations and Coalitions`,
  }
]

function MemberOrgTypes(props) {
  const isClickable = props.isClickable;
  if (isClickable) {
    return org_types.map(org_type => (
      <button 
        onClick={() => props.onClick(org_type.id)} 
        className={`cell c-card-button ${org_type.id === props.active ? `active` : 'u-text--light'}`}
        key={org_type.id}
        >
        <IconSection
        image={org_type.icon}
        imageSize=''
        alttext={org_type.text}
        text={org_type.text}
        align='center'
        />
       </button>
      ));
    }
    return org_types.map(org_type => (
        <IconSection
        cell
        image={org_type.icon}
        imageSize=''
        alttext={org_type.text}
        text={org_type.text}
        align='center'
        key={org_type.id}
        />
    ));
  }

export default MemberOrgTypes;
