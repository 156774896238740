import React, { Component }from 'react';
import {
  Grid,
  Cell,
} from 'react-foundation';
import {icons, graphics} from './constants';
import Icon from './icon';

const myFeatures = [
  {
    title: 'Branded Web and Mobile Apps',
    id: 'featureWhiteLabel',
    description: 'A fully white label product: your logo, your colors, your words, your favicon, your brand.',
    icon: `${icons.desktop}`,
    graphic: `${graphics.whiteLabel}`,
  },
  {
    title: 'Course, Task and Resource Management',
    id: 'featureTracks',
    description: 'Create, organize and assign courses, tasks and resources with tracks and actions.',
    icon: `${icons.wrench}`,
    graphic: `${graphics.tracks}`,
  },
  {
    title: 'Member and Team Management',
    id: 'featureMembers',
    description: 'Organize your members by Team Tags, Teams, Team Roles, and Groups.',
    icon: `${icons.members}`,
    graphic: `${graphics.members}`,
  },
  {
    title: 'Messaging and Forums',
    id: 'featureMessaging',
    description: 'Connect your community through Discussion Forums, Inbox and Groups.',
    icon: `${icons.envelope}`,
    graphic: `${graphics.discussions}`,
  },
  {
    title: 'Rewards and Certification',
    id: 'featureRewards',
    description: 'Track and celebrate member’s participation with Points, Badges and Rewards.',
    icon: `${icons.trophy}`,
    graphic: `${graphics.rewards}`,
  },
  {
    title: 'Events and Volunteer Management',
    id: 'featureEvents',
    description: 'Schedule in-person and online events, log attendance and assign volunteer duties.',
    icon: `${icons.calendar}`,
    graphic: `${graphics.events}`,
  },
  {
    title: 'Analytics and Reporting',
    id: 'featureReporting',
    description: 'Measure engagement, participation and usage with detailed reporting tools.',
    icon: `${icons.graph}`,
    graphic: `${graphics.analytics}`,
  }
]

function HomeFeatureText(props) {
  const features = props.features;
  return features.map(feature => (
      <div
        className={`${feature.id === props.active ? 'active' : ''} c-feature__link`}
        key={feature.id}
        onClick={() => props.onClick(feature.id)}>
        <button className="c-feature__title">
          <Icon icon={feature.icon} className="c-icon--left c-icon--w1"/>
          <span>{feature.title}</span>
        </button>
        {feature.id === props.active && <span className="c-feature__description">{feature.description}</span>}
      </div>
  ));
}

function HomeFeatureGraphic(props) {
  const features = props.features;
  return features.map(feature => (
    <div>
    {feature.id === props.active && <img
      src={feature.graphic}
      className="c-feature__graphic"
      key={feature.id}
      alt={feature.title}
    />}
    </div>
  ));
}

class FeatureCarousel extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      active: 'featureWhiteLabel',
    };
  }

  handleClick = id => {
    this.setState({
      active: id,
    });
  };

  render() {
    return (
      <Grid className="grid-x grid-margin-x">
        <Cell className="small-12 medium-6 c-feature__graphic-wrapper">
          <HomeFeatureGraphic
            features={myFeatures}
            active={this.state.active}
          />
        </Cell>
        <Cell className="small-12 medium-5">
          <h2 className="u-text--secondary">Manage your programs, foster your community.</h2>
          <p className="u-text--small">Host all your community engagement needs in one place. Crew's expansive suite of features are designed to grow with your movement.</p>
          <HomeFeatureText
            features={myFeatures}
            onClick={this.handleClick}
            active={this.state.active}
          />
          <a href="/features" className="u-text--mt50 button primary-inverse">View all features &#8594;</a>
        </Cell>
      </Grid>
    );
  }
}
export default FeatureCarousel;
