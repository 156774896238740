import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop';
import Routes from './components/routes';
import './scss/App.scss';

function App() {

  return (
  <BrowserRouter>
    <ScrollToTop>
      <Routes />
    </ScrollToTop>
  </BrowserRouter>
  );
}

export default App;
