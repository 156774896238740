import React, { Component }from 'react';
import {
  Grid,
  Cell
} from 'react-foundation';
import TestimonialCard from './testimonialCard.js';
import MemberOrgTypes from './memberOrgTypes.js';
import {memberOrgs, icons} from './constants';

const testimonials = [
  {
    org: `${memberOrgs.feelGood.org}`,
    id:`volunteerMovement`,
    primaryColor:`${memberOrgs.feelGood.primaryColor}`,
    image: `${memberOrgs.feelGood.staff}`,
    icon: `${icons.hands}`,
    type: `${memberOrgs.feelGood.type}`,
    author: 'Chloe Kund',
    role: 'Program Director',
    quote: 'The Crew platform allows us to equip students with the resources they need to educate their peers and communities about the realities of chronic hunger and poverty and the tools to create a non-profit social enterprise!'
  },
  {
    org: `${memberOrgs.milleniumFellowship.org}`,
    id: 'fellowship',
    primaryColor:`${memberOrgs.milleniumFellowship.primaryColor}`,
    image: `${memberOrgs.milleniumFellowship.staff}`,
    icon: `${icons.fellowship}`,
    type: `${memberOrgs.milleniumFellowship.type}`,
    author: 'Noha Al-Khalqi',
    role: 'Program Director',
    quote: `${memberOrgs.milleniumFellowship.quote}`,
  },
  {
    org: `${memberOrgs.centerForSupportiveSchools.org}`,
    id: 'educationMovement',
    primaryColor:`${memberOrgs.centerForSupportiveSchools.primaryColor}`,
    image: `${memberOrgs.centerForSupportiveSchools.staff}`,
    icon: `${icons.chalkboard}`,
    type: `${memberOrgs.centerForSupportiveSchools.type}`,
    author: 'Renelle Lawrence',
    role: 'Director of Digital & Community School Design',
    quote: `${memberOrgs.centerForSupportiveSchools.quote}`
  },
  {
    org: `${memberOrgs.netImpact.org}`,
    id: 'competition',
    primaryColor:`${memberOrgs.netImpact.primaryColor}`,
    image: `${memberOrgs.netImpact.staff}`,
    icon: `${icons.badge}`,
    type: `${memberOrgs.netImpact.type}`,
    author: 'Hillary Allen',
    role: 'Program Manager',
    quote: `${memberOrgs.netImpact.quote}`
  },
  {
    org: `${memberOrgs.freeForestSchool.org}`,
    id: 'associationsCoalitions',
    primaryColor:`${memberOrgs.freeForestSchool.primaryColor}`,
    image: `${memberOrgs.freeForestSchool.staff}`,
    icon: `${icons.network}`,
    type: `${memberOrgs.freeForestSchool.type}`,
    author: 'Elizabeth Corinth',
    role: 'Board Member',
    quote: 'The Crew Platform allows us to empower our community members, through simple orientations and training modules, to get outside with their children, to meet up with one another, and to form communities of mutual support. We collect donations and facilitate in-person meet ups in every state in the US!'
  }
]

function TestimonialQuote(props) {
  const testimonials = props.testimonials;
  return testimonials.map(testimonial => (
      <div>
      {testimonial.id === props.active &&
        <TestimonialCard
        image={testimonial.image}
        author={testimonial.author}
        role={testimonial.role}
        org={testimonial.org}
        type={testimonial.type}
        quote={testimonial.quote}
        primaryColor={testimonial.primaryColor}
        key={testimonial.id}
        />
      }
      </div>
  ));
}

class TestimonialBlock extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      active: 'volunteerMovement',
    };
  }

  handleClick = id => {
    this.setState({
      active: id,
    });
  };

  render() {
    return (
      <Grid className="grid-x grid-margin-x">
        <Cell className="small-12">
          <Grid className="grid-x grid-margin-x medium-up-3 large-up-5">
            <MemberOrgTypes
              isClickable={true}
              cell
              active={this.state.active}
              onClick={this.handleClick}
            />
          </Grid>
          <TestimonialQuote
            testimonials={testimonials}
            active={this.state.active}
          />
        </Cell>
      </Grid>
    );
  }
}
export default TestimonialBlock;
