import React from 'react';
import {heros} from '../constants.js'
import Header from '../header';
import Footer from '../footer';

const DemoScheduledPage = () => {
	return (
		<div id="registration_success">		
			<div>
				<Header color="dark"/>
				<div>
					<div className="grid-container o-section--large">
						<img src={heros.highFiveHero} alt="Success" className="c-image--400 c-icon--main c-icon--center"/>
						<h2 className="u-bold u-text--primary u-center">Thank you for scheduling a Crew Demo! Please check your email for the call details.</h2>
					</div>
				</div>
				<div className="grid-container o-section--large">
			</div>
				<Footer absoluteBottom={1}/>
			</div>
		</div>
		
	)
}

export default DemoScheduledPage;