import React, {useState} from 'react';
import Modal from './modal';

function InsertModal(props) {

 const [open, setIsOpen] = useState(false);

 const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  return (
    <div>
        <button className={props.buttonClass} onClick={openModal}>{props.buttonText}</button>
        {open && 
          <Modal 
          open={setIsOpen} 
          onClose={closeModal} 
          content={props.content}       
      />
        }
    </div>
  );
}

export default InsertModal;