import React, { Component } from 'react';
import crewForAll_squiggle from '../img/logos/crewForAll_squiggle.svg';

export class CallToAction extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.form = React.createRef();
    this.successMessage = React.createRef();
    this.errorMessage = React.createRef();
  }

  handleSubmit(event) {
    const scriptURL = "https://script.google.com/macros/s/AKfycbx33MT193EYF40QO8_F2Rq9tj8wSyGIhrNJOYSgZ5rhOQJ9QM9R3qEoJ1aJF0t3dNf7vQ/exec"
    event.preventDefault()
    fetch(scriptURL, { method: 'POST', body: new FormData(this.form.current)})
      .then(response => this.successMessage.current.classList = "u-center u-bold u-text--success")
      .catch(error => this.errorMessage.current.classList = "u-center u-bold u-text--alert")
    }

  render() {
  return (
  <div className="u-gradient--gray u-center o-section--large">
    <img src={crewForAll_squiggle} alt="logo" className="c-fill-white u-text--mb1"/>
    <h3 className="u-bold u-text--mb1">{this.props.headline}</h3>
    <p className="u-center">{this.props.description}</p>
    {this.props.comingSoon &&
      <div>
      <p ref={this.successMessage} className="u-center u-bold u-text--success u-hidden">Success! Your email was submitted. We will send you an email when Crew For All is released!</p>
      <p ref={this.errorMessage} className="u-center u-bold u-text--alert u-hidden">Oh no! Something went wrong. Try again or message support@crew2030.org for more assistance.</p>
      <form onSubmit={this.handleSubmit} ref={this.form} method="post" action="" className="c-form--one-field c-input__sign-up">
        <input type="email" name="Email"  placeholder="Email" required/>
        <input type="submit" value="Submit" className="button primary" />
      </form>
      </div>
    }
    {!this.props.comingSoon &&
    <div className="c-button-group">
      {this.props.grantButton && <a href="https://linktr.ee/crew2030" target="_blank" rel="noopener noreferrer" className="primary-inverse button">Apply for a Crew Grant!</a>}
      {
        this.props.link ?
        <a href={this.props.link} target="_blank" rel="noopener noreferrer" className="primary button c-button--inline">{this.props.buttonText}</a>
        :
        <a onClick={this.props.onClick} className="primary button c-button--inline">{this.props.buttonText}</a>
      }
    </div>
    }
  </div>
  )
  }
}
export default CallToAction;
