import React from 'react';
import {
  Grid,
  Cell,
} from 'react-foundation';


function AlternatingGridView(props, grid) {
  const section = props.grid;

  return section.map((section, index) =>
    <Grid className="grid-x grid-margin-x" key={section.id} >
      <Cell className={`small-12 medium-5  o-section--small ${index%2 ? "medium-order-2 " : ""}`}>
          <img src={section.image} className="c-image--450 c-responsive-image" alt={section.title} />
      </Cell>
      <Cell className="small-12 medium-7 o-section--small" key={section.id}>
        {section.url &&  <a className="u-link--primary-hover" rel="noopener noreferrer" target="_blank" href={section.url}><h3 className="u-title">{section.title}</h3></a> }
        {!section.url &&  <h3 className="u-title">{section.title}</h3> }
        <h6 className="u-subtitle u-bold">{section.subtitle}</h6>
        <div>
          {
            section.list.map(((listItem, index) => {
              return (
                <div>
                {listItem.listTitle &&  <h6 className="u-bold">{listItem.listTitle}</h6>}
                  <p className="u-text--small" key={listItem.index}>
                    {listItem.listDescription}
                  </p>
                </div>
              )
            })
          )
          }
        </div>
      </Cell>
    </Grid>
  );
}

export default AlternatingGridView;
