import React from 'react';

function Modal(props) {
  return (
    <div className="c-overlay">
      <div className="c-modal">
      <div>{props.content}</div>
      <button className="c-modal__close u-bold" onClick={props.onClose}>&times;</button>
      </div>
    </div>
  );
}

export default Modal;