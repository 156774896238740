import React, {useEffect, useState} from 'react';
import {heros} from '../constants.js'
import Header from '../header';
import Footer from '../footer';

const RegistrationSuccessPage = (props) => {
	const [needActivation, setNeedActivation] = useState(false);

	useEffect(() => 
	{
		const search = props.location.search;
		const token = new URLSearchParams(search).get('token');
		if (token)
		{
			window.location = `https://www.crewplatform.org/connect/token_login?token=${token}&redirect=/platform/config`;
		} else {
			setNeedActivation(true);
		}
	});
	return (
		<div id="registration_success">		
			{
				needActivation && 
					<div>
					<Header color="dark"/>
					<div>
						<div className="grid-container o-section--large">
							<img src={heros.highFiveHero} alt="Success" className="c-image--400 c-icon--main c-icon--center"/>
							<h2 className="u-bold u-text--primary u-center">An email with an activation link was sent to your email account. Please click on that link to continue.</h2>
						</div>
					</div>
					<div className="grid-container o-section--large">
        </div>
					<Footer absoluteBottom={1}/>
				</div>
			}
		</div>
		
	)
}

export default RegistrationSuccessPage;