import React from 'react';
import crewForAll from '../img/logos/crewForAll.svg';

function Crew2030() {
  return(
    <div>
      <img src={crewForAll} alt="logo" className="c-fill-white c-image--100 c-icon--center u-text--mb1"/>
      <h4 className="u-text--mb1 text-center">Announcing <span className="u-bold">Crew For All!</span></h4>
      <p className="u-text--small u-text--mb1">As of July 1, 2022 the Crew Platform will be offered at no cost, as a gift to the world's changemakers and leaders.  This gift is made possible by the generous contributions of Enlight Foundation, Wendy’s Wish Giving Fund, and Amazon Web Services. Read along to learn more about what’s staying the same and what’s changing.</p>
      <ul>
      <li className="u-text--mb50"><span className="u-bold">The Crew Platform will be free!</span> The new 'Build your Crew' process will begin on our website and will be a self-managed wizard/process. In terms of free support, all members will have access to our Crew Support and Tutorials platform to access documentation, videos, daily group office hours, and ongoing webinars.</li>
      <li className="u-text--mb50"><span className="u-bold">We are launching Crew Pro</span> to offer personalized onboarding, one-on-one coaching, and custom features. See the <a href="/crewPlus" className="u-text--primary">Crew Pro page for investment pricing</a>. </li>
      <li className="u-text--mb50"> <span className="u-bold">New Terms of Service</span> Following best practices in the community platforms space, our current Subscription Agreement and Terms of Use will be merged with our new Terms of Service that will cover all of our Terms from the perspective of the platform creators and users.</li>
      </ul>
      <p></p>
    </div>
    );
} 
export default Crew2030;
