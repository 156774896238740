import React from 'react';

function TestimonialCard(props) {
  const style = {
    color: `${props.primaryColor}`
  }
  return (
    <div className={`c-testimonial ${props.bubble === true ? 'c-testimonial--bubbles' : ''}`}>
    {props.image &&
      <img className="c-image--300" src={props.image} alt={props.author}/>
    }

    <div className="c-card c-card--shadow c-testimonial__card">
      <div className="o-spectrum">
        {props.rating && 
          <img className="c-image--100 u-text--mb1" src={props.rating} alt='rating'/>
        }
        <h3 className={`u-title ${props.small === true ? 'u-hidden' : ''}`} style={style}>{props.org}</h3>
        <h6 className="u-subtitle">{props.type}</h6>
      </div>
      <p className={`${props.small === true ? 'u-text--small' : ''}`}>{props.quote}</p>
      <span className={`${props.small === true ? 'u-text--small' : ''} u-bold`} style={style}>{props.author}, {props.role}</span>
    </div>
      {props.bubble &&
          <img src={props.rightBubble} className="c-testimonial__right-bubble" alt=""></img>
      }
    </div>
  );
}

export default TestimonialCard;
