import React from 'react';
import IconSection from './iconSection';
import { Grid } from 'react-foundation';
import {memberOrgs} from './constants';

const logos = [
  `${memberOrgs.centerForSupportiveSchools.logo}`,
  `${memberOrgs.divInc.logo}`,
  `${memberOrgs.feelGood.logo}`,
  `${memberOrgs.freeForestSchool.logo}`,
  `${memberOrgs.citizenUniversity.logo}`, 
  `${memberOrgs.iftf.logo}`,
  `${memberOrgs.liif.logo}`,
  `${memberOrgs.milleniumFellowship.logo}`,
  `${memberOrgs.moveTexas.logo}`,
  `${memberOrgs.nazun.logo}`,
  `${memberOrgs.netImpact.logo}`,
  `${memberOrgs.operationSmile.logo}`, 
  `${memberOrgs.projectInvent.logo}`,
  `${memberOrgs.swipeOutHunger.logo}`,
  `${memberOrgs.texasCivilRightsProject.logo}`,
  `${memberOrgs.yourBigYear.logo}`
];


// const orgLogos = Object.entries(memberOrgs).slice(0, 16).map(([key, i]) => {
const displayedLogos = 
  logos.map((logo, index) => {
    return (
      <IconSection
      image={logo}
      imageSize='c-image--100'
      key={index}
      />
    );
  });

//Images
function MemberOrgs() {
  return (
    <Grid className="o-row-section">
    {displayedLogos}
    </Grid>
  );
}

export default MemberOrgs;
