import React from 'react';


function Banner(props){
	return (
	<div className={`c-banner--${props.color} u-center`}>
		<img src={props.icon} alt={props.altText} className="c-icon--w1_5 c-icon--left"/>
		<span className={`icon__text u-text--${props.color}`}>{props.text}</span>
	</div>
	)
}

export default Banner;
