import React, { Component } from 'react';
import Header from '../header';
import Footer from '../footer';
import {
  Grid,
  Cell
} from 'react-foundation';

export class PrivacyPolicyPage extends Component {
  render() {
    return (
      <div id="privacyPolicy">
        <Header color="dark"/>
        <div className="grid-container sticky">
          <Grid className="grid-margin-x">
            <Cell className="small-12 o-section--large">
              <h2 className="u-bold">Privacy Policy</h2>
              <h3 className="u-bold">Crew 2030 | Crew Platform Support</h3>
              <p className="u-text--small">Last Updated: February 9, 2021 </p>
              <p className="u-text--small">Crew 2030 (“<span className="u-bold">Crew Platform</span> ,” “<span className="u-bold">we</span> ,” or “<span className="u-bold">us</span> ”) values the privacy of  individuals who use our websites, <a className="u-text--primary" href="https://www.crewplatform.org">crew2030.org</a> and <a className="u-text--primary" href="https://www.crewplatform.org">crewplatform.org</a>, applications for customer organizations, and related services (collectively, our “<span className="u-bold">Services</span>”). This privacy policy (the “<span className="u-bold">Privacy Policy</span>”) explains how we collect, use, and share personal information from Crew2030 users (“<span className="u-bold">Users</span>,” <span className="u-bold">you</span>,” and/or “<span className="u-bold">your</span>”). By using our Services, you agree to the collection, use, disclosure, and procedures in this <a href="https://www.crew2030.org/privacypolicy" className="u-text--primary">Privacy Policy</a>.</p>
              <p className="u-text--small">This Privacy Policy applies to our website visitors and to our customers who use our Services. Our processing of personal information collected as a service provider and data processor for our customer organizations is on behalf of that customer. For example, customer organizations may customize their sign-up page to collect certain information from you such as your address and phone number. They may also allow you to make a donation to them. Such customer organizations are the data controllers, and their privacy policies will apply to the processing of your information. We encourage you to read the privacy policies of customer organizations to which you belong.</p>
              <ul className="menu vertical">
                <li><span className="u-bold">1. Information We Collect.</span> We may collect a variety of personal information from or about you or your devices from various sources, as described below:
                  <ul className="menu">
                    <li>In this Privacy Policy “<span className="u-bold">personal information</span>” means any information related to an identified or identifiable individual, and does not include data whereby personally identifiable information has been removed (such as anonymous data).</li>
                  </ul>
                </li>
                <li><span className="u-bold">1a. Information You Provide to Us.</span>
                  <ul className="menu c-menu--nested">
                    <li><span className="u-bold">Registration and Profile Information.</span> When you sign up for an account we ask you for information, such as your first and last name, email address, graduation year, and team.</li>
                    <li><span className="u-bold">Contact information.</span> We process your name and email address to provide our Services to customer organizations.</li>
                    <li><span className="u-bold">Communications.</span> If you contact us directly, we may receive additional information about you. For example, when you contact our Customer Support Team, we will receive your name, email address, phone number, the organization you are part of, the contents of a message or attachments that you may send to us, and other information you choose to provide.</li>
                    <li><span className="u-bold">Event and Location Information.</span> When you use the “Find Events” feature on our Services, you may provide us with an address (e.g., your home address or another location) to search for events near you. You may also provide us with other event data, such as the confirmation of your attendance for an event. If you create an event on our Services, you may provide us with information about the event you are hosting, such as the name and description of the event, location of the event, and time.</li>
                    <li><span className="u-bold">Donation Information.</span> If you decide to make a donation on our Services, you may provide us with certain information such as your name, email, address and the amount of your donation. If you make a donation, a third party service provider that handles payments for us will receive your payment card information.</li>
                  </ul>
                </li>
                <li><span className="u-bold">1b. Information We Collect When You Use Our Services.</span>
                  <ul className="menu c-menu--nested">
                    <li><span className="u-bold">Location Information.</span> When you use our Services, we infer your general location information (for example, your IP address may indicate your general geographic region).</li>
                    <li><span className="u-bold">Device Information.</span> We receive information about the device and software you use to access our Services, including internet protocol (IP) address, web browser type, operating system version, phone carrier and manufacturer, application installations, device identifiers, mobile advertising identifiers, and push notification tokens.</li>
                    <li><span className="u-bold">Usage Information.</span> To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with our Services, like activities you are part of, your comments, any content you post, and the dates and times of your visits.</li>
                    <li><span className="u-bold">Information from Cookies and Similar Technologies.</span> We and third party partners collect information using cookies, pixel tags, or similar technologies (“<span className="u-bold">cookies</span>”). Our third party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services. Cookies are small text files containing a string of alphanumeric characters. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our Services. See “<span className="u-bold">Cookies</span>” Section 3 below for further detail.</li>
                  </ul>
                </li>
                <li><span className="u-bold">1c. Information We Receive from Third Parties.</span>
                  <ul className="menu c-menu--nested">
                    <li><span className="u-bold">Other third parties.</span> We may receive additional information about you, from third parties such as data or marketing partners and combine it with other information we have about you.</li>
                    <li>Payment Processor.  We obtain Personal Information about you from third parties, such as our payment processors Stripe and PayPal. This information includes transaction information, such as details of payments made, VAT due, and the timings of such payments.</li>
                  </ul>
                </li>
                <li><span className="u-bold">2. How We Use the Information We Collect.</span> We use the personal information we collect to:
                  <ul className="menu c-menu--nested">
                    <li><span className="u-bold">Provide you with the Services.</span> We use your personal information, including contact and transaction information, to provide and maintain our Services.  In particular we will use your personal information to perform our contractual obligation towards you to allow you to create an account, browse the Services, purchase services, make payments and donations, and maintain a record of such transactions, and to contact you in case of any issue with your account;</li>
                    <li><span className="u-bold">Event location and creation.</span> We use your personal information to allow you to create events on our Services, to find events through our “Find Events” feature, to confirm your attendance for events and to contact individuals who confirmed their attendance for an event you created; </li>
                    <li><span className="u-bold">Improve and monitor usage of our Services.</span> It is in our legitimate interests to improve our Services for our users, and analyze the use of our Services, which includes troubleshooting, research, testing, and analyzing how you use our Services to develop and enhance new products, services, features, and functionality. When doing so, the personal information we use includes that which we automatically collect about you;</li>
                    <li><span className="u-bold">Marketing.</span> We use your information for marketing purposes, such as developing and providing promotional and advertising materials that may be useful, relevant, valuable or otherwise of interest to you;</li>
                    <li><span className="u-bold">Communicate with you.</span> If you reach out to us for support, or otherwise have questions or requests, we will perform our contractual obligation towards you by using your personal information to respond to our queries and facilitate support. The personal information we process when doing so includes [your correspondence with us, your name, contact details, and, to the extent applicable to your query or complaint, transaction and usage information;</li>
                    <li><span className="u-bold">Find and prevent fraud, and respond to trust and safety issues that may arise.</span> It is in our legitimate interests to ensure the integrity and safety of our Services. When doing so, we will process personal information including registration and communication information, and device and usage information;</li>
                    <li><span className="u-bold">For compliance and legal purposes.</span> It is in our legitimate interests to process your personal information to enforce our TOS or other legal rights, to defend ourselves against legal claims or disputes, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency. When we do so, we will use personal information relevant to such a case. Some processing may also be necessary to comply with a legal obligation placed on Crew2030; and </li>
                    <li>For other purposes for which we provide specific notice at the time the personal information is collected.</li>
                  </ul>
                </li>
                <li><span className="u-bold">3. Cookies.</span> Our Services use cookies to collect information about your browsing activities and to distinguish you from other users of our Services.  This aids your experience when you browse our Services, allows us to improve the functionality of our Services, increase security, measure use and effectiveness of our Services and advertise our Services elsewhere. We use the following cookies:
                  <ul className="menu c-menu--nested">
                    <li><span className="u-bold">Strictly necessary cookies:</span> These cookies are strictly necessary to make our Services available to you; for example, to perform your login functionality and for user authentication and security. We cannot provide you with the Services without this type of cookie.
                    <table>
                       <thead>
                         <tr>
                           <th width="300">Cookie Name</th>
                           <th>Provider</th>
                           <th>Purpose</th>
                           <th>Expiry</th>
                          </tr>
                         </thead>
                         <tbody>
                          <tr>
                           <td>ci_session_t</td>
                           <td>Crew platform</td>
                           <td>Maintain login session</td>
                           <td>One year</td>
                          </tr>
                        </tbody>
                    </table>
                    </li>
                    <li><span className="u-bold">Functional cookies:</span> These are used to recognize you when you return to our Services.  This enables us to personalize our content for you, greet you by name and remember your account preferences.
                    <table>
                       <thead>
                         <tr>
                           <th width="300">Cookie Name</th>
                           <th>Provider</th>
                           <th>Purpose</th>
                           <th>Expiry</th>
                          </tr>
                         </thead>
                         <tbody>
                          <tr>
                           <td>remember_login</td>
                           <td>Crew platform</td>
                           <td>Remember login name</td>
                           <td>One year</td>
                          </tr>
                          <tr>
                           <td>remember_community</td>
                           <td>Crew platform</td>
                           <td>Remember community</td>
                           <td>One year</td>
                          </tr>
                          <tr>
                           <td>__stripe_mid</td>
                           <td>Crew/Stripe </td>
                           <td>ID used to securely process payments</td>
                           <td>One year</td>
                          </tr>
                          <tr>
                           <td>__stripe_sid</td>
                           <td>Crew/Stripe </td>
                           <td>ID used to securely process payments</td>
                           <td>Thirty minutes</td>
                          </tr>
                          <tr>
                           <td>DV</td>
                           <td>Google</td>
                           <td>Google Maps Preferences</td>
                           <td>Ten minutes</td>
                          </tr>
                          <tr>
                           <td>NID</td>
                           <td>Google</td>
                           <td>Google Preferences</td>
                           <td>Six months</td>
                          </tr>
                          <tr>
                           <td></td>
                           <td></td>
                           <td></td>
                           <td></td>
                          </tr>
                        </tbody>
                    </table>
                    </li>
                    <li><span className="u-bold">Analytical or performance cookies:</span> We use cookies for analytics purposes in order to operate, maintain, and improve our Website and Services.  We use third party analytics providers, including Google Analytics, to help us understand how users engage with the Services. Google Analytics uses first-party cookies to track User interactions which helps show how users use our Services.  This information is used to compile reports and to help us improve our Services.  The reports disclose Website trends without identifying individual visitors.  You can learn about Google’s practices by going to <a href="https://www.google.com/policies/privacy/partners/" className="c-menu-link--inline u-text--primary">Google Partner&#39;s Privacy Policy</a> and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at <a href="https://tools.google.com/dlpage/gaoptout/" className="c-menu-link--inline u-text--primary">Google Analytics Opt Out</a>.
                    <table>
                       <thead>
                         <tr>
                           <th width="300">Cookie Name</th>
                           <th>Provider</th>
                           <th>Purpose</th>
                           <th>Expiry</th>
                          </tr>
                         </thead>
                         <tbody>
                          <tr>
                           <td>_ga</td>
                           <td>Google Analytics</td>
                           <td>To store and count pageviews for statistics</td>
                           <td>One year</td>
                          </tr>
                          <tr>
                           <td>_gid</td>
                           <td>Google Analytics</td>
                           <td>To store and count pageviews for statistics</td>
                           <td>One year (crewplatform.org)<br/>Two years (crew2030.org)</td>
                          </tr>
                          <tr>
                           <td>_gat_gtag_UA_*</td>
                           <td>Google Analytics / Tags</td>
                           <td>To store a unique user ID for statistics</td>
                           <td>One minute</td>
                          </tr>
                          <tr>
                           <td>__gat_UA-*</td>
                           <td>Google Analytics / Tags</td>
                           <td>To store a unique user ID for statistics</td>
                           <td>One minute</td>
                          </tr>
                          <tr>
                           <td>1P_JAR</td>
                           <td>Google Analytics</td>
                           <td>ID for website statistics</td>
                           <td>One month</td>
                          </tr>
                          <tr>
                           <td>OptanonConsent</td>
                           <td>OneTrust</td>
                           <td>Store information about user consent based on which the cookies will be set on the browser</td>
                           <td>One year</td>
                          </tr>
                        </tbody>
                    </table>
                    </li>
                  </ul>
                You can block cookies by setting your internet browser to block some or all or cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Services. All cookies except session cookies will expire within 12 months.
                </li>
                <li><span className="u-bold">4. How We Share the Information We Collect</span>
                  <ul className="menu c-menu--nested">
                    <li><span className="u-bold">Affiliates.</span> We may share any information we receive with our affiliates for any of the purposes described in this Privacy Policy.</li>
                    <li><span className="u-bold">Vendors and Service Providers.</span> We may share any information we receive with vendors and service providers retained in connection with the provision of our Services, including cloud and server hosting providers such as AWS, communication service providers such as Postmark App, and web mapping API providers such as Google Maps.</li>
                    <li><span className="u-bold">User Content.</span> Our Services enable you to share content. Your name, organization, and other profile information will always be viewable and searchable by other users. The content you post to the Services will be displayed on the Services and viewable by other users. We are not responsible for the other users’ use of available information, so you should carefully consider whether and what to post or how you identify yourself on the Services. If you confirm your attendance for an event on our Service, this confirmation will be shared with the host of the event.</li>
                    <li><span className="u-bold">Payment Processors.</span> We disclose your information to payment processors in order to enable organizations to receive payments and donations on our Services, such as Stripe. Further information on how Stripe uses your Personal Information can be found at https://stripe.com/privacy. </li>
                    <li><span className="u-bold">Analytics Partners.</span> We use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps, and online resources. You can learn about Google’s practices by going to <a href="https://www.google.com/policies/privacy/partners/" className="c-menu-link--inline u-text--primary">Google Partner&#39;s Privacy Policy</a> and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at <a href="https://tools.google.com/dlpage/gaoptout/" className="c-menu-link--inline u-text--primary">Google Analytics Opt Out</a>.</li>
                    <li><span className="u-bold">As Required By Law and Similar Disclosures.</span> We may access, preserve, and disclose your information if we believe doing so is required or appropriate to: (a) comply with law enforcement requests and legal process, such as a court order or subpoena; (b) respond to your requests; or (c) protect your, our, or others’ rights, property, or safety. For the avoidance of doubt, the disclosure of your information may occur if you post any objectionable content on or through the Services.</li>
                    <li><span className="u-bold">Merger, Sale, or Other Asset Transfers.</span> We may transfer your information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our assets. The use of your information following any of these events will be governed by the provisions of this Privacy Policy in effect at the time the applicable information was collected.</li>
                    <li><span className="u-bold">Consent.</span> We may also disclose information from or about you and your devices with your permission.</li>
                  </ul>
                </li>
                <li><span className="u-bold">5. Your Choices</span>
                  <ul className="menu c-menu--nested">
                    <li><span className="u-bold">Privacy Settings.</span> We provide you with settings to allow you to set your privacy preferences.  These allow you to control what information other users see.</li>
                    <li><span className="u-bold">Marketing Communications.</span> You can unsubscribe from our promotional emails via the link provided in the emails. Even if you opt-out of receiving promotional messages from us, you will continue to receive administrative messages from us about your account.</li>
                    <li><span className="u-bold">Do Not Track.</span> There is no accepted standard on how to respond to Do Not Track signals, and we do not respond to such signals.</li>
                    <li>In certain circumstances, depending on where you are based and where applicable by law, you may have the following rights in relation to your Personal Information that we hold.
                      <ul className="menu c-menu--nested c-menu--bullets">
                        <li><span className="u-bold">Access.</span> You have the right to access the personal information we hold about you, and to receive an explanation of how we use it and who we share it with.</li>
                        <li><span className="u-bold">Correction.</span> You have the right to correct any personal information we hold about you that is inaccurate or incomplete.</li>
                        <li><span className="u-bold">Erasure.</span> You have the right to request for your personal information to be erased or deleted.</li>
                        <li><span className="u-bold">Object to processing.</span> You have the right to object to our processing of your personal information where we are relying on a legitimate interest.</li>
                        <li><span className="u-bold">Restrict processing.</span> You have a right in certain circumstances to stop us processing your personal information other than for storage purposes.</li>
                        <li><span className="u-bold">Portability.</span> You have the right to receive, in a structured, commonly used and machine-readable format, personal information that you have provided to us if we process it on the basis of our contract with you, or with your consent, or to request that we transfer such personal information to a third party.</li>
                        <li><span className="u-bold">Withdraw consent.</span>  Where we are processing your personal information with your consent, you can withdraw your consent at any time.</li>
                      </ul>
                      Please note that, prior to any response to the exercise of such rights, we will require you to verify your identify. In addition, we may have valid legal reasons to refuse your request, and will inform you if that is the case. For more information on your rights, please email <a href="mailto:privacy@crew2030.org" className="u-text--primary c-menu-link--inline">privacy@crew2030.org</a>
                    </li>
                  </ul>
                </li>
                <li><span className="u-bold">6. Third Parties.</span> Our Services may contain links to other websites, products, or services that we do not own or operate.  We are not responsible for the privacy practices of these third parties. Please be aware that this Privacy Policy does not apply to your activities on these third party services or any information you disclose to these third parties. We encourage you to read their privacy policies before providing any information to them.</li>
                <li><span className="u-bold">7. Security.</span> We make reasonable efforts to protect your information by using physical and electronic safeguards designed to improve the security of the information we maintain. However, as our Services are hosted electronically, we can make no guarantees as to the security or privacy of your information.</li>
                <li><span className="u-bold">8. Children’s Privacy.</span> We do not knowingly collect, maintain, or use personal information from children under 16 years of age, and no part of our Services are directed to children. If you learn that a child has provided us with personal information in violation of this Privacy Policy, then you may alert us at <a href="mailto:info@crew2030.org" className="u-text--primary c-menu-link--inline">info@crew2030.org</a>.</li>
                <li><span className="u-bold">9. International Visitors.</span> Our Services are hosted in the United States. If you choose to use the Services from the European Economic Area (EEA), Switzerland or the United Kingdom or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring your personal information outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Services. When we do so, we shall ensure that relevant safeguards are in place to afford adequate protection for your Personal Information. Further details regarding the relevant safeguards can be obtained from us on request.</li>
                <li><span className="u-bold">10. Update Your Information.</span> You can update your account and profile information or close your account through your profile settings.</li>
                <li><span className="u-bold">11. Changes to this Privacy Policy.</span> We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share personal information previously collected from you through the Services, we will notify you through the Services, by email, or other communication.</li>
                <li><span className="u-bold">12. Contact Information.</span> If you have any questions, comments, or concerns about our processing activities, please email us at <a href="mailto:info@crew2030.org" className="u-text--primary c-menu-link--inline">info@crew2030.org</a> or write to us at <span className="u-bold">PO Box 341892, Austin, Texas 78734</span>. If you are located in the EEA or the UK,  you may contact our EU and UK Representative for questions or to exercise your data protection rights by: (i) sending an email to datarequest@datarep.com quoting in the subject line; (ii) contacting them via their online webform at www.datarep.com/data-request; or (iii) mailing your inquiry to the most convenient address on the list available here. <span className="u-bold">When mailing inquiries, please address your letter to ‘DataRep’ and not ‘Crew 2030’.</span>
                <p className="u-text--small">><span className="u-bold">List of DataRep postal mail addresses:</span></p>
                <table>
                  <thead>
                    <tr>
                      <th width="300">Country</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Austria</td>
                      <td>DataRep, City Tower, Bruckenkopfgasse 1/6. Stock, Graz, 8020, Austria</td>
                    </tr>
                    <tr>
                      <td>Belgium</td>
                      <td>DataRep, Place de L&#39;Universite 16, Louvain-La-Neuve, Waals Brabant, 1348, Belgium</td>
                    </tr>
                    <tr>
                      <td>Bulgaria</td>
                      <td>DataRep, 132 Mimi Balkanska Str., Sofia, 1540, Bulgaria</td>
                    </tr>
                    <tr>
                      <td>Croatia</td>
                      <td>DataRep, Ground & 9th Floor, Hoto Tower, Sayska cesta 32, Zagreb, 10000, Croatia</td>
                    </tr>
                    <tr>
                      <td>Cyprus</td>
                      <td>DataRep, Victory House, 205 Archbishop Makarios Avenue, Limassol, 3030, Cyprus</td>
                    </tr>
                    <tr>
                      <td>Czech Republic</td>
                      <td>DataRep, IQ Ostrava Ground floor, 28. rijna 3346/91, Ostrava-mesto, Morayska, Ostrava, Czech Republic</td>
                    </tr>
                    <tr>
                      <td>Denmark</td>
                      <td>DataRep, Lautruphoj 1-3, Ballerup, 2750, Denmark</td>
                    </tr>
                    <tr>
                      <td>Estonia</td>
                      <td>DataRep, 2nd Floor, Tornimae 5, Tallinn, 10145, Estonia</td>
                    </tr>
                    <tr>
                      <td>Finland</td>
                      <td>DataRep, Luna House, 5.krs, Mannerheimintie 12 B, Helsinki, 00100, Finland</td>
                    </tr>
                    <tr>
                      <td>France</td>
                      <td>DataRep, 72 rue de Lessard, Rouen, 76100, France</td>
                    </tr>
                    <tr>
                      <td>Germany</td>
                      <td>DataRep, 3rd and 4th floor, Altmarkt 10 B/D, Dresden, 01067, Germany</td>
                    </tr>
                    <tr>
                      <td>Greece</td>
                      <td>DataRep, 24 Lagoumitzi str, Athens, 17671, Greece</td>
                    </tr>
                    <tr>
                      <td>Hungary</td>
                      <td>DataRep, President Centre, Kalman Imre utca 1, Budapest, 1054, Hungary</td>
                    </tr>
                    <tr>
                      <td>Iceland</td>
                      <td>DataRep, Kalkofnsvegur 2, 101 Reykjavik, Iceland</td>
                    </tr>
                    <tr>
                      <td>Ireland</td>
                      <td>DataRep, The Cube, Monahan Road, Cork, T12 H1XY, Republic of Ireland</td>
                    </tr>
                    <tr>
                      <td>Italy</td>
                      <td>DataRep, BPM 335368, Via Roma 12, 10073 , Cirie TO, Italy</td>
                    </tr>
                    <tr>
                      <td>Latvia</td>
                      <td>DataRep, 4th & 5th floors, 14 Terbatas Street, Riga, LV-1011, Latvia</td>
                    </tr>
                    <tr>
                      <td>Liechtenstein</td>
                      <td>DataRep, City Tower, Bruckenkopfgasse 1/6. Stock, Graz, 8020, Austria</td>
                    </tr>
                    <tr>
                      <td>Lithuania</td>
                      <td>DataRep, 44A Gedimino Avenue, 01110 Vilnius, Lithuania</td>
                    </tr>
                    <tr>
                      <td>Luxembourg</td>
                      <td>DataRep, BPM 335368, Banzelt 4 A, 6921, Roodt-sur-Syre, Luxembourg</td>
                    </tr>
                    <tr>
                      <td>Malta</td>
                      <td>DataRep, Tower Business Centre, 2nd floor, Tower Street, Swatar, BKR4013, Malta</td>
                    </tr>
                    <tr>
                      <td>Netherlands</td>
                      <td>DataRep, Cuserstraat 93, Floor 2 and 3, Amsterdam, 1081 CN, Netherlands</td>
                    </tr>
                    <tr>
                      <td>Norway</td>
                      <td>DataRep, C.J. Hambros Plass 2c, Oslo, 0164, Norway</td>
                    </tr>
                    <tr>
                      <td>Poland</td>
                      <td>DataRep, Budynek Fronton ul Kamienna 21, Krakow, 31-403, Poland</td>
                    </tr>
                    <tr>
                      <td>Portugal</td>
                      <td>DataRep, Torre de Monsanto, Rua Afonso Praca 30, 7th floor, Alges, Lisbon, 1495-061, Portugal</td>
                    </tr>
                    <tr>
                      <td>Romania</td>
                      <td>DataRep, 15 Piaa Charles de Gaulle, nr. 1-T, Bucure§ti, Sectorul 1, 011857, Romania</td>
                    </tr>
                    <tr>
                      <td>Slovakia</td>
                      <td>DataRep, Apollo Business Centre II, Block E / 9th floor, 4D Prievozska, Bratislava, 821 09, Slovakia</td>
                    </tr>
                    <tr>
                      <td>Slovakia</td>
                      <td>DataRep, Apollo Business Centre II, Block E / 9th floor, 4D Prievozska, Bratislava, 821 09, Slovakia</td>
                    </tr>
                    <tr>
                      <td>Slovenia</td>
                      <td>DataRep, Trg. Republike 3, Floor 3, Ljubljana, 1000, Slovenia</td>
                    </tr>
                    <tr>
                      <td>Spain</td>
                      <td>DataRep, BPM 335368, Avd. Castilla La Mancha N° 70-1 (Nave A), 45270, Mocejon-Toledo, Spain</td>
                    </tr>
                    <tr>
                      <td>Sweden</td>
                      <td>DataRep, S:t Johannesgatan 2, 4th floor, Malmo, SE - 211 46, Sweden</td>
                    </tr>
                    <tr>
                      <td>United Kingdom</td>
                      <td>DataRep, BPM 335368, 372 Old Street, EC1V 9AU, London, United Kingdom</td>
                    </tr>
                  </tbody>
                </table>
                <p className="u-text--small">Upon receiving your communication, DataRep may request evidence of your identity, to ensure your personal information is not provided to anyone other than you. If you have any concerns over how DataRep will handle your personal information required to undertake its services, please refer to <a href="www.datarep.com/privacy-policy" className="u-text--primary c-menu-link--inline">DataRep&#39;s Privacy Policy</a></p>
                <p className="u-text--small">If you wish to lodge a complaint about how we process your Personal Information, please contact us at <a href="mailto:info@crew2030.org" className="u-text--primary c-menu-link--inline">info@crew2030.org</a>. We will endeavor to respond to your complaint as soon as possible. You may also lodge a claim with the <a href="https://ico.org.uk/make-a-complaint/" className="u-text--primary c-menu-link--inline">Information Commissioner’s Office in the UK</a> or the data protection supervisory authority in the EEA country in which you live or work, where you believe we have infringed data protection laws.</p>
                </li>
              </ul>
              <p className="u-text--small">Crew 2030 © 2020 | Austin, TX<br />Crew 2030 is a 501c3 non-profit organization.<br /> Privacy Policy</p>
            </Cell>
          </Grid>
        </div>
        <Footer />
      </div>
    )
  }
}

export default PrivacyPolicyPage;
