import React, { Component } from 'react';
import Header from '../header';
import Footer from '../footer';


class NewsPage extends Component {
  handleClick = () => {
	const script = document.createElement("script");
	script.src = "https://io.dropinblog.com/js/embed.js";
	script.async = true;
	document.body.appendChild(script);
  }
  render() {
    return (
      <div id="news" onClick={this.handleClick()}>
        <Header color="dark"/>
        <div className="sticky grid-container">
          <div id="dib-posts"></div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default NewsPage;
