import React from 'react';

function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      className={`${props.className} c-icon`}
      width={`${props.size}`}
      height={`${props.size}`}
      viewBox={`0 0 ${props.viewportWidth} ${props.viewportHeight}`}
    >
      <g>
        <path
        d={props.icon}
      ></path>
      </g>
    </svg>
  );
};

Icon.defaultProps = {
  className: '',
  size: '100%',
  viewportWidth: 24,
  viewportHeight: 24
};

export default Icon;
