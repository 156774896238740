import React from 'react';
import {graphics} from './constants'
import AlternatingGridView from './alternatingGridView';

const featureDescriptions = [
  {
    id: 'coreFeatures',
    title: 'Onboard, engage and organize your members.',
    description: '',
    image: `${graphics.featureTracks}`,
    list: [
      {
        listTitle: 'Tracks',
        listDescription: 'Crew’s core feature to host your educational and/or onboarding content, webinars, task lists and guides for users. Create collaborative tracks, dependencies, add deadlines, post videos, collect and share responses (including multimedia content) and more!'
      },
      {
        listTitle: 'Events',
        listDescription: 'Crew Events allow you to create virtual or in-person events, add them to your preferred calendar, track RSVPs and attendance, and post volunteer shifts including time slots & descriptions. Virtual events offer a (new!) live chat feed, and in-person events offer an embedded Google Maps integration!'
      },
      {
        listTitle: 'Groups',
        listDescription: 'Create mini communities within your platform with their own members, tracks, events, channels and discussions!'
      },
      {
        listTitle: 'Rewards and Gameful Engagement',
        listDescription: 'Use badges and prizes to gamify your platform. Customize how to earn badges, use our points system to allow users to earn real prizes of your choice!'
      }
    ]
  },
  {
    id: 'communicationsFeatures',
    title: 'Centralize your communication efforts.',
    description: 'Collect responses, post questions, message and chat users all on Crew.',
    image: `${graphics.featureCommunications}`,
    list: [
      {
        listTitle: 'Forms',
        listDescription: 'Build a custom sign-up form (with payment!) to collect the information relevant to your organization. Coming soon: add custom forms to tracks, groups, and events!'
      },
      {
        listTitle: 'Alerts, Notifications and Messaging',
        listDescription: 'Create platform wide alert banners and pop-up modals, receive notifications for platform activity, and send and receive messages directly to your Crew inbox.'
      },
      {
        listTitle: 'Email',
        listDescription: 'Push reminders, messages and notifications directly to members email with branded HTML emails.'
      },
      {
        listTitle: 'Ratings and Reflections',
        listDescription: 'Collect track ratings, responses and discussions and share with others.'
      },
      {
        listTitle: 'Forum & Channels',
        listDescription: 'Use our discussion forum to encourage conversation, answer questions and see what’s happening in your community. Create community channels to share insights around a subject, or allow users to make their own!'
      },
    ]
  },
  {
    id: 'adminFeatures',
    title: 'Administrators Deserve a Good Experience, Too.',
    description: 'Crew’s admin panel is as robust and user friendly as the platform itself.',
    image: `${graphics.featureAdmins}`,
    list: [
      {
        listTitle: 'Payment Processing',
        listDescription: 'Accept donations and collect membership dues on sign up! Coming soon: payment processing for tracks, groups and events!'
      },
      {
        listTitle: 'Customize Platform Experience and Account Creation',
        listDescription: 'Our White Label Platform means you can customize your mobile and desktop site to be completely your own: logos, branding, platform terminology, default texts and more. You can also customize your account creation process, complete with Single Sign On for Google and Azure.'
      },
      {
        listTitle: 'Member Management',
        listDescription: 'Tag and organize users with our teams, team tags and roles: by location, chapter, year or whatever custom field you choose. Then use that tag throughout the platform to create user dashboards, promote events, send messages and more.'
      },
      {
        listTitle: 'Program Management',
        listDescription: 'Edit, categorize and organize your tracks, groups and events in our admin control panel and program wizards.'
      },
      {
        listTitle: 'Reports and Analytics',
        listDescription: 'Use our integrated analytics to create and save reports and export any data you need.'
      }
    ]
  }
]

function FeaturesList() {
  return (
    <div className="o-section--large grid-container">
      <AlternatingGridView
        grid={featureDescriptions}
      />
    </div>
  )
}

export default FeaturesList;
