import React, { Component } from 'react';
import Header from '../header';
import Footer from '../footer';
import ContactForm from '../contactForm';


class ContactPage extends Component {
  render() {
    return (
      <div id="contact">
        <Header color="dark" />
        <div className="sticky">
          <div className="grid-container o-section--large">
            <h1 className="u-center">Let's Collaborate!</h1>
            <p class="u-description--center">We’d love to hear from you. Get in touch to brainstorm how we can support your movement building and member engagement efforts.</p><br></br>
            <ContactForm />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default ContactPage;
