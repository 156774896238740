import React from 'react';
import { Grid } from 'react-foundation';
import MemberExperienceFeatures from './memberExperience';

function BigStatement(props) {
  const subtitle = props.subtitle;
  const image = props.image;
  const memberExperience = props.memberExperience;
  const paragraph = props.paragraph;

  return (
    <div className={`u-gradient--${props.gradient} o-section--large c-big-statement`}>
      <div className="grid-container">
        <div className={`${props.direction} c-big-statement__wrapper`}>
          <div className="o-inner-section">
            {subtitle &&
              <h6 className={`text-center u-subtitle ${props.textColor}`}>{props.subtitle}</h6>
            }
            {image &&
              <img src={props.image} className="c-icon--main" alt=""/>
            }
            <h2 className={`u-bold ${props.textColor}`}>{props.headline}</h2>
          </div>

          {memberExperience &&
            <Grid className="o-inner-section grid-margin-x small-up-2 large-up-3">
              <MemberExperienceFeatures />
            </Grid>
          }

          {paragraph &&
            <p>{props.paragraph}</p>
          }
        </div>
      </div>
    </div>
  )
}
export default BigStatement;
