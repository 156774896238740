import React, { Component } from 'react';
import Header from '../header';
import Footer from '../footer';
import {Grid} from 'react-foundation';
import CrewImpact from '../crewImpact';
import OurCrew from '../ourCrew';
import {icons} from '../constants.js'

export class AboutPage extends Component {
  render() {
    return (
      <div id="about">
        <div className="u-gradient--about">
          <Header color="light"/>
          <div className="sticky">
            <div className="grid-container o-section--large">
              <img src={icons.colorCircle} alt="icon" className="c-image--100 c-icon--main c-icon--center"/>
              <h2 className="u-bold u-text--white u-center">Crew For All equips program leaders with a powerful community platform to make the world a more loving, just and sustainable place.</h2>
            </div>
          </div>
        </div>
        <div className="grid-container o-section--large">
          <h3 className="u-center u-text--mb2">We envision a connected social sector sharing infrastructure and best practices, evolving together to build a world that works for all.</h3>
          <h6 className="text-center u-subtitle u-text--mb1">Our Intended Impact</h6>
          <Grid className="grid-margin-x medium-up-3">
            <CrewImpact />
          </Grid>
        </div>
        <div className="c-image--friends o-section--image o-section--large o-section--center top">
          <h2 className="u-bold u-center o-section__headline">We're building technology in community.</h2>
          <p className="u-text--small u-bold u-center u-subtitle">250+ Nonprofits &#183; 140 Countries &#183; 57k+ members served &#183; a 93% Renewal Rate</p>
        </div>
          <OurCrew />
        <Footer />
      </div>
    )
  }
}

export default AboutPage;
